<template>
  <div>

    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.title">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-data-table
          dense
          class="mt-2"
          :headers="headers2"
          :items="list_of_request_pending"
          hide-default-footer
          disable-pagination
          height="450"
        >
          <template v-slot:item="{ item }">
            <tr
              @click="activerow(item)"
              :class="{ 'info white--text': item.id === selectedDepositId }"
            >
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ compute_total(item.request_data) }}
              </td>
              <td v-if="item.is_approved === 0">
                {{ '(Created By: '+item.created_by.last_name+', '+
                item.created_by.first_name+')'}}
              </td>
              <td v-if="item.is_approved === 2 && item.approved_by!=null">
                <v-chip color="info" dark>
                  NEED FINAL APPROVAL
                </v-chip>
                {{ '(Created By: '+item.created_by.last_name+', '+
                item.created_by.first_name+') Approve By: '+item.approved_by.last_name+', '+
                item.approved_by.first_name}}
              </td>
              <td v-if="item.is_approved === 1 && item.approved_by!=null">
                {{ '(Created By: '+item.created_by.last_name+', '+
                item.created_by.first_name+') Approve By: '+item.approved_by.last_name+', '+
                item.approved_by.first_name}}
              </td>
              <td class="text-center" v-if="item.is_approved===0">
                <v-icon
                  v-if="!is_deleting_request && item.is_approved === 0&&(is_head === 1||(item.created_by.user_data[0].position==='EMPLOYEE'&&position!='EMPLOYEE'))"
                  class="mr-2"
                  color="warning"
                  @click="change_status_pending_request(item.id, 0)"
                >
                  {{ icons.mdiThumbDown }}
                </v-icon>
                <v-progress-circular
                  color="info"
                  indeterminate
                  v-if="is_deleting_request"
                ></v-progress-circular>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="!is_deleting_request && item.is_approved === 0&&(is_head === 1||(item.created_by.user_data[0].position==='EMPLOYEE'&&position!='EMPLOYEE'))"
                  class="mr-2"
                  color="primary"
                  @click="change_status_pending_request(item.id, 1)"
                >
                  {{ icons.mdiThumbUp }}
                </v-icon>
                <v-progress-circular
                  color="info"
                  indeterminate
                  v-if="is_deleting_request"
                ></v-progress-circular>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="
                                      !is_deleting_request && item.created_id === employee_id && item.is_approved === 0
                                    "
                  class="mr-2"
                  color="error"
                  @click="delete_pending_request(item.id)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
                <v-progress-circular
                  color="info"
                  indeterminate
                  v-if="is_deleting_request"
                ></v-progress-circular>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          dense
          class="mt-2"
          :headers="headers"
          :items="list_of_request_vouchered"
          hide-default-footer
          disable-pagination
          height="450"
        >
          <template v-slot:item="{ item }">
            <tr
              @click="activerow(item)"
              :class="{ 'info white--text': item.id === selectedDepositId }"
            >
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.category.category }}
              </td>
              <td>
                {{ compute_total(item.request_data) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          dense
          class="mt-2"
          :headers="headers"
          :items="list_of_request_credited"
          hide-default-footer
          disable-pagination
          height="450"
        >
          <template v-slot:item="{ item }">
            <tr
              @click="activerow(item)"
              :class="{ 'info white--text': item.id === selectedDepositId }"
            >
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.category.category }}
              </td>
              <td>
                {{ compute_total(item.request_data) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import {
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiDelete,
    mdiThumbDown,
    mdiThumbUp,
    mdiPencil,
    mdiGoogleCirclesCommunities,
    mdiAccountOutline,
    mdiPrinter,
    mdiCashCheck,
  } from '@mdi/js'
  import {mapActions} from "vuex";

  export default {
    props: {
      employee_id: Number,
      is_head: Number,
      position: String,

      list_of_request_pending: Array,
      list_of_request_vouchered: Array,
      list_of_request_credited: Array,
      tabs: Array,
      headers2: Array,
      headers: Array,
    },
    setup() {
      return {
        tab: '',
        selectedDepositId: 0,
        is_deleting_request: false,
        icons: {
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiDelete,
          mdiThumbDown,
          mdiThumbUp,
          mdiPencil,
          mdiPrinter,
          mdiCashCheck,
        },
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      activerow(value) {
        this.selectedDepositId = value.id
        this.$emit('activerow', value)
      },
      compute_total(data) {
        var tto = 0
        data.forEach(function (item) {
          tto += parseFloat(item.amount)
        })
        return this.formatPrice(tto)
      },
      delete_pending_request(id) {
        this.$emit('delete_pending_request', id)
      },
      change_status_pending_request(id, value) {
        this.$emit('change_status_pending_request', id, value)
      },
    }
  }
</script>

<style scoped>
</style>
