<template>
  <div>
    <request>

    </request>
  </div>
</template>
<script>
  import Request from './RequestV2'

  export default {
    components: {
      Request,
    },
  }
</script>
