<template>
  <div>
    <v-row dense>
      <v-col md="12" cols="12">
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col md="3" cols="12">
              <v-card flat dense>
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title
                  ><h5 class="font-weight-light">{{ current_page }}</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col md="12" cols="12">
                      <v-select
                        v-model="month_of_id"
                        class="mx-2"
                        :items="month_of_items"
                        item-value="id"
                        item-text="month_of"
                        label="Month Of"
                        required
                        :rules="rules.combobox_rule"
                        dense
                        @change="selected_month"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12" v-if="is_ftv">
                      <v-select
                        class="mx-2"
                        v-model="ftv_option"
                        dense
                        label="Option"
                        :items="['Regular FTV', 'Inter-Company FTV']"
                        :rules="rules.combobox_rule"
                        @change="check_ftv_initialize('from')"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12" v-if="!is_ftv">
                      <v-select
                        v-if="position != 'EMPLOYEE'"
                        class="mx-2"
                        v-model="category_id"
                        dense
                        label="Company"
                        :items="category_items"
                        item-value="id"
                        item-text="category"
                        :rules="rules.combobox_rule"
                        @change="selected_month"
                      ></v-select>
                      <v-text-field
                        v-else
                        class="mx-2"
                        v-model="category_data"
                        dense
                        label="Category"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12" v-if="is_ftv">
                      <v-select
                        class="mx-2"
                        v-model="category_id"
                        dense
                        label="From Category"
                        :items="category_items"
                        item-value="id"
                        item-text="category"
                        :rules="rules.combobox_rule"
                        @change="check_ftv_initialize('from')"
                      ></v-select>
                    </v-col>
                    <v-col md="12" cols="12" v-if="is_ftv && ftv_option==='Inter-Company FTV'">
                      <v-select
                        class="mx-2"
                        v-model="category_id_to"
                        dense
                        label="To Category"
                        :items="category_items_to"
                        item-value="id"
                        item-text="category"
                        :rules="rules.combobox_rule"
                        @change="check_ftv_initialize('to')"
                      ></v-select>
                    </v-col>
                    <v-col
                      md="12"
                      cols="12"
                      v-if="((is_have_pending_request && !is_forbidden) || position === 'EMPLOYEE')&&!is_ftv"
                    >
                      <v-text-field
                        class="mx-2"
                        v-model="request_from"
                        dense
                        label="Request From"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12" v-else>
                      <v-select
                        v-if="!is_forbidden &&!is_ftv"
                        class="mx-2"
                        v-model="request_from"
                        dense
                        label="Request From"
                        :items="['Branch', 'Regional', 'Territory']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>

                    <!-- alert -->
                    <v-col cols="12" v-show="alert">
                      <v-alert color="warning" text class="mb-0">
                        <div class="d-flex align-start">
                          <v-icon color="warning">
                            {{ icons.mdiAlertOutline }}
                          </v-icon>

                          <div class="ms-3">
                            <p class="text-base font-weight-medium mb-1">
                              {{ alert_message }}
                            </p>
                          </div>
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col
                      v-if="
                        month_of_items
                          .map(function (x) {
                            return x.id
                          })
                          .indexOf(month_of_id) === 0
                      "
                    >
                      <v-col cols="12" md="12" v-if="is_can_save">
                        <v-btn class="w-full" color="primary" @click="save_request"
                               v-if="!saving_request">
                          Save changes
                        </v-btn>
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="primary"
                          indeterminate
                          v-else
                        ></v-progress-circular>
                      </v-col>
                      <v-col cols="12" md="12"
                             v-if="is_have_pending_request && request_data.length === 0">
                        <v-btn class="w-full" color="error" @click="delete_request"
                               v-if="!is_deleting_request">
                          Delete Request
                        </v-btn>
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="primary"
                          indeterminate
                          v-else
                        ></v-progress-circular>
                      </v-col>
                    </v-col>
                    <v-col md="12" cols="12">
                      <h2>List of Request &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <v-icon @click="is_view_list_of_request=true">
                          {{icons.mdiArrowExpand}}
                        </v-icon>
                      </h2>

                      <ListOfRequest
                        :key="this.refresh_list_of_request"
                        :list_of_request_pending="this.list_of_request_pending"
                        :list_of_request_vouchered="this.list_of_request_vouchered"
                        :list_of_request_credited="this.list_of_request_credited"
                        :employee_id="this.employee_id"
                        :is_head="this.is_head"
                        :position="this.position"
                        :tabs="this.tabs"
                        :headers2="this.headers2"
                        :headers="this.headers"
                        v-on:activerow="activerow"
                        v-on:delete_pending_request="delete_pending_request"
                        v-on:change_status_pending_request="change_status_pending_request"
                      ></ListOfRequest>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="9" cols="12">
              <request-information
                v-if="is_have_pending_request && selectedDepositId === 0"
                v-on:data="on_save_request_data"
                :key="is_have_pending_request && selectedDepositId === 0"
                :category_id="this.category_id"
                :request_id="this.request_id"
                :is_regular="this.is_regular"
                :is_pdc="this.is_pdc"
                :is_ftv="this.is_ftv"
                :branch_items="this.branch_items"
                :particulars_items="this.particulars_items"
                :from_bank_items="this.from_bank_items"
                :to_bank_items="this.to_bank_items"
                :is_from_other="0"
                :employee_branch_data="this.employee_branch_data"
              ></request-information>
              <v-card>
                <v-card-text>
                  <v-row dense>
                    <v-col md="12" cols="12">
                      <RequestData
                        :key="this.key"
                        :request_data="this.request_data"
                        :total_amount="this.total_amount"
                        :selectedDepositId="this.selectedDepositId"
                        :is_have_pending_request="this.is_have_pending_request"
                        :is_deleting_req="this.is_deleting_request"
                        :can_up_del="this.can_up_del"
                        :month_of_items="this.month_of_items"
                        :month_of_id="this.month_of_id"
                        :is_regular="this.is_regular"
                        :is_pdc="this.is_pdc"
                        :is_ftv="this.is_ftv"

                        v-on:edit_request_data="edit_request_data"
                        v-on:delete_data="delete_data"
                      ></RequestData>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="6" cols="12"></v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-model="EditInfoRequest" persistent max-width="50%">
      <request-information
        v-on:data="on_save_request_data"
        :key="this.key"
        :category_id="this.category_id"
        :request_id="this.request_id"
        :is_regular="this.is_regular"
        :is_pdc="this.is_pdc"
        :is_ftv="this.is_ftv"
        :branch_items="this.branch_items"
        :particulars_items="this.particulars_items"
        :from_bank_items="this.from_bank_items"
        :to_bank_items="this.to_bank_items"
        :is_from_other="1"
        :from_others="this.selected_request_data"
      ></request-information>
      <v-btn color="error" @click="EditInfoRequest = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_view_list_of_request" persistent>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h5 class="font-weight-light">{{ current_page }}</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-row dense class="mt-3">
          <v-col md="12" cols="12">
            <v-row dense>
              <v-col md="4" cols="12">
                <ListOfRequest
                  :key="this.refresh_list_of_request"
                  :list_of_request_pending="this.list_of_request_pending"
                  :list_of_request_vouchered="this.list_of_request_vouchered"
                  :list_of_request_credited="this.list_of_request_credited"
                  :employee_id="this.employee_id"
                  :is_head="this.is_head"
                  :position="this.position"
                  :tabs="this.tabs"
                  :headers2="this.headers2"
                  :headers="this.headers"
                  v-on:activerow="activerow"
                  v-on:delete_pending_request="delete_pending_request"
                  v-on:change_status_pending_request="change_status_pending_request"
                ></ListOfRequest>
              </v-col>
              <v-col md="8" cols="12">
                <RequestData
                  :key="this.key"
                  :request_data="this.request_data"
                  :total_amount="this.total_amount"
                  :selectedDepositId="this.selectedDepositId"
                  :is_have_pending_request="this.is_have_pending_request"
                  :is_deleting_req="this.is_deleting_request"
                  :can_up_del="this.can_up_del"
                  :month_of_items="this.month_of_items"
                  :month_of_id="this.month_of_id"
                  :is_regular="this.is_regular"
                  :is_pdc="this.is_pdc"
                  :is_ftv="this.is_ftv"

                  v-on:edit_request_data="edit_request_data"
                  v-on:delete_data="delete_data"
                ></RequestData>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-btn color="error" @click="is_view_list_of_request = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiDelete,
    mdiThumbDown,
    mdiThumbUp,
    mdiPencil,
    mdiGoogleCirclesCommunities,
    mdiAccountOutline,
    mdiPrinter,
    mdiCashCheck,
    mdiArrowExpand,
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import RequestInformation from './request_components_v2/RequestInformation'
  import ListOfRequest from './request_components_v2/ListOfRequest'
  import RequestData from './request_components_v2/RequestData'

  const initialState = () => {
    return {
      key: 0,
      refresh_list_of_request: 0,
      saving_request: false,
      saving_request_data: false,
      alert: false,
      alert_message: '',

      is_view_list_of_request: false,

      alert_da: false,
      alert_message_da: '',
      request_from: '',

      request_data: [],

      ftv_option: '',

      tab: '',
      tabs: [
        {title: 'PENDING', icon: mdiAccountOutline},
        {title: 'VOUCHER', icon: mdiGoogleCirclesCommunities},
        {title: 'CREDITED', icon: mdiCashCheck},
      ],

      list_of_request_pending: [],
      list_of_request_vouchered: [],
      list_of_request_credited: [],
      list_of_request_not_yet_approved: {},
      headers2: [
        {text: 'ID', value: '', sortable: false},
        {text: 'Amount', value: '', sortable: false},
        {text: '', value: '', sortable: false},
        {text: '', value: '', sortable: false},
        {text: '', value: '', sortable: false},
      ],
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Category', value: 'category.category', sortable: false},
        {text: 'Amount', value: '', sortable: false},
      ],
      category_id: '',
      category_items: [],
      category_id_to: '',
      category_items_to: [],
      month_of_id: 0,
      month_of_items: [],
      selectedDepositId: 0,
      current_page: '',
      is_regular: 0,
      is_pdc: 0,
      is_ftv: 0,
      is_have_pending_request: false,
      is_can_save: false,
      EditInfoRequest: false,

      is_forbidden: false,
      request_id: 0,
      total_amount: '0.00',

      branch_items: [],
      particulars_items: [],

      from_bank_items: [],
      to_bank_items: [],
      is_deleting: false,

      selected_request_data: {},
      category_data: '',
      can_up_del: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
      RequestInformation,
      ListOfRequest,
      RequestData,
    },
    setup() {
      return {
        icons: {
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiDelete,
          mdiThumbDown,
          mdiThumbUp,
          mdiPencil,
          mdiPrinter,
          mdiCashCheck,
          mdiArrowExpand,
        },
        is_deleting_request: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },

    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['name', 'employee_id', 'position', 'is_head']),
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('request_particulars', ['request_particulars_data']),
      ...mapActions('request_v2_s', [
        'initialize_request_v2_form',
        'create_request_v2',
        'delete_request_v2',
        'change_status_request_v2',
      ]),
      ...mapActions('request_v2_data', ['delete_request_data_v2', 'initialize_edit_request_data']),
      async initialize_data() {
        switch (this.$router.currentRoute.name) {
          case 'new-regular-request-v2':
            this.current_page = 'REGULAR REQUEST V2'
            this.is_regular = 1
            this.is_pdc = 0
            this.is_ftv = 0
            break
          case 'new-pdc-request-v2':
            this.current_page = 'PDC REQUEST V2'
            this.is_regular = 0
            this.is_pdc = 1
            this.is_ftv = 0
            break
          case 'new-ftv-request-v2':
            this.current_page = 'FTV REQUEST V2'
            this.is_regular = 0
            this.is_pdc = 0
            this.is_ftv = 1
            // this.category_items.splice(0, 0, {
            //   id: 2,
            //   category: 'HO',
            // })
            break
        }
        await this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
            this.category_items = response.data[0].category
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
        if (this.position === 'EMPLOYEE') {
          this.category_id = this.employee_category_id
          this.category_data =
            this.category_items[
              this.category_items
                .map(function (x) {
                  return x.id
                })
                .indexOf(this.category_id)
              ].category
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      activerow(value) {
        if (this.is_have_pending_request) {
          this.change_snackbar({
            show: true,
            color: 'error',
            text: 'PLEASE COMPLETE YOUR REQUEST FIRST!!!',
          })
        } else {
          this.request_data = value.request_data
          this.selectedDepositId = value.id
          this.total_amount = this.compute_total(value.request_data)
          this.can_up_del = ((value.created_by.user_data[0].position === 'EMPLOYEE' && this.position != 'EMPLOYEE')
            ||
            (value.created_by.user_data[0].position != 'EMPLOYEE' && this.is_head)) ? true : false
        }
      },
      on_save_request_data(value) {
        this.key++
        this.refresh_list_of_request++
        this.EditInfoRequest = false
        this.selected_month()
      },
      async check_ftv_initialize(type) {
        if (this.is_ftv && this.ftv_option === 'Inter-Company FTV' && type === 'from') {
          if (this.category_id != this.category_id_to) {
            this.category_items_to = this.category_items
          }
        }
        if (this.category_id === this.category_id_to) {
          this.category_items_to = []
          this.category_id = 0
          this.category_id_to = ''
        }
        this.selected_month()
      },
      async selected_month() {
        this.is_can_save = false
        this.key++
        this.refresh_list_of_request++
        this.can_up_del = false
        this.selectedDepositId = 0
        const data = new FormData()
        data.append('month_of_id', this.month_of_id)
        data.append('category_id', this.category_id)
        data.append('category_id_to', this.category_id_to)
        data.append('is_regular', this.is_regular)
        data.append('is_pdc', this.is_pdc)
        data.append('is_ftv', this.is_ftv)
        data.append('created_id', this.employee_id)
        data.append('position', this.position)
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        }

        await this.initialize_request_v2_form(data)
          .then(response => {
            this.list_of_request_not_yet_approved = response.data[0].pending_request
            this.list_of_request_pending = response.data[0].data
            this.list_of_request_vouchered = response.data[0].data2
            this.list_of_request_credited = response.data[0].data3
            this.is_have_pending_request = response.data[0].is_have_pending
            this.branch_items = response.data[0].branches
            this.particulars_items = response.data[0].particulars
            this.from_bank_items = response.data[0].banks
            this.to_bank_items = response.data[0].banks
            if (this.category_id_to != '') {
              this.to_bank_items = response.data[0].to_banks
            }
            this.request_data = []
            this.request_from = ''
            if (this.is_have_pending_request) {
              this.request_data = this.list_of_request_not_yet_approved.request_data
              this.request_id = this.list_of_request_not_yet_approved.id
              this.request_from = 'Branch'
              if (this.list_of_request_not_yet_approved.is_regional === 1) {
                this.request_from = 'Regional'
              } else if (this.list_of_request_not_yet_approved.is_territory === 1) {
                this.request_from = 'Territory'
              }
            }
            this.total_amount = this.compute_total(this.request_data)
          })
          .catch(error => {
            console.log(error)
          })
        if (this.position === 'EMPLOYEE') {
          if (this.employee_branch_data.is_region === 0 && this.employee_branch_data.is_territory === 0) {
            this.request_from = 'Branch'
          } else if (this.employee_branch_data.is_region === 1) {
            this.request_from = 'Regional'
          } else if (this.employee_branch_data.is_territory === 1) {
            this.request_from = 'Territory'
          }
        }
        this.is_can_save = !this.is_have_pending_request
      },
      compute_total(data) {
        var tto = 0
        data.forEach(function (item) {
          tto += parseFloat(item.amount)
        })
        return this.formatPrice(tto)
      },
      delete_data(id) {
        if (confirm('PROCEED?')) {
          this.is_deleting = true
          const data = new FormData()
          data.append('id', id)
          this.delete_request_data_v2(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.is_deleting = false
              this.selected_month()
            })
            .catch(error => {
              console.log(error)
              this.is_deleting = false
            })
        }
      },
      async edit_request_data(value) {
        if (confirm('PROCEED?')) {
          await this.initialize_edit_request_data({
            id: value.request_id,
          })
            .then(response => {
              this.branch_items = response.data[0].branches
              this.particulars_items = response.data[0].particulars
              this.from_bank_items = response.data[0].banks
              this.to_bank_items = response.data[0].banks
            })
            .catch(error => {
              console.log(error)
            })
          this.key++
          this.EditInfoRequest = true
          this.request_id = value.request_id
          this.selected_request_data = value
        }
      },
      save_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          var is_regional = 0
          var is_territory = 0
          if (this.request_from === 'Regional') {
            is_regional = 1
          } else if (this.request_from === 'Territory') {
            is_territory = 1
          }
          data.append('category_id', this.category_id)
          data.append('month_of_id', this.month_of_id)
          data.append('created_id', this.employee_id)
          data.append('is_regular', this.is_regular)
          data.append('is_pdc', this.is_pdc)
          data.append('is_ftv', this.is_ftv)
          data.append('is_regional', is_regional)
          data.append('is_territory', is_territory)
          this.create_request_v2(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.saving_request = false
              this.selected_month()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving_request = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
      delete_request() {
        if (confirm('PROCEED?')) {
          this.is_deleting_request = true
          this.alert = false
          const data = new FormData()
          data.append('request_id', this.request_id)
          this.delete_request_v2(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.is_deleting_request = false
              this.selected_month()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.is_deleting_request = false
            })
        }
      },
      delete_pending_request(id) {
        this.request_id = id
        this.delete_request()
      },
      change_status_pending_request(id, value) {
        if (confirm('PROCEED?')) {
          this.is_deleting_request = true
          this.alert = false
          const data = new FormData()
          data.append('request_id', id)
          data.append('approved_id', this.employee_id)
          data.append('status', value)
          this.change_status_request_v2(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.is_deleting_request = false
              this.selected_month()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.is_deleting_request = false
            })
        }
      },

    },
  }
</script>
